<template>
    <div class="idle d-flex flex-column">
        <template v-if="title">
            <div class="d-flex">
                <h1 class="idle-station-title de m-0 ">
                    {{ title.de }}
                </h1>
            </div>
            <div class="d-flex">
                <h1 class="idle-station-title en m-0 mt-3">
                    {{ title.en }}
                </h1>
            </div>
        </template>
        <template v-if="description">
            <p class="idle-instruction de m-0 mt-4">
                {{ description.de }}
            </p>
            <p class="idle-instruction en m-0 mt-3">
                {{ description.en }}
            </p>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TitleAndDescriptionIdleMode',
    props: {
        title: {
            type: Object,
            default: () => {},
        },
        description: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped>
.idle {
    position: absolute;
    z-index: 100;
    left: calc(50vw * var(--pxToVw));
    top: calc(50vw * var(--pxToVw));
    font-weight: 500;

    &-station-title {
        padding: 0 calc(16vw * var(--pxToVw));
        color: var(--primary);
        background-color: var(--primary-bg);
        text-transform: uppercase;
    }

    &-instruction {
        font-family: 'GoodProCn', GoodProCn;
        font-size: calc(30vw * var(--pxToVw));
        width: calc(500vw * var(--pxToVw));
    }
}
</style>
